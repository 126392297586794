.pill-button {
    border-radius: 50px; /* Set a large border radius to make it pill-shaped */
    padding: 10px 20px; /* Adjust padding as needed */
    font-size: 1.5rem; /* Adjust font size as needed */
    /* Add any additional styling you desire */
    background-color: #EDB;
    color: black;
    transition: background-color 0.3s ease; /* Add a transition for smoother hover effect */
    border: none;
    outline: none;

}

.pill-button:hover {
/* Add hover styles if desired */
    background-color: #00272B; /* Change the background color on hover */
    color: white; /* Change text color on hover if needed */
    border: none;
    outline: none;

}

/* Optionally, you can add additional styles for better visual focus indication */
.pill-button:focus {
    box-shadow: none !important; /* Example: Add a custom box shadow on focus */
    outline: none;
    border: none;
}